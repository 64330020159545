.yaaga__logo-section {
  div:first-child {
    max-width: 1110px;
    margin: 0 auto;
    h6 {
      font-family: TomatoGrotesk;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      color: #292929;
      margin: 0 0 24px 0;
    }
    div {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }

    span:nth-child(1) {
      grid-column: 1 / span 1;
      img {
        width: 100%;
      }
    }
    span:nth-child(2) {
      position: relative;
      grid-column: 2 / span 1;
      img:first-child {
        width: 100%;
      }
      img:last-child {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        width: 50%;
      }
    }

    img:nth-child(3) {
      grid-column: 1 / span 2;
      width: 100%;
    }
  }
}
