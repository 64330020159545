.responsive-error-page {
  height: 500px;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-top: 100px;
  h5 {
    text-align: center;
    font-weight: 400;
    margin-top: 12px;
    font-size: xx-large;
  }
  p {
    text-align: center;
    font-weight: 300;
    margin-top: 12px;
    font-size: large;
  }
  &::before {
    position: absolute;
    display: block;
    content: " ";
    width: 40px;
    height: 40px;
    left: 25%;
    border-radius: 60%;
    background-color: rgb(0, 186, 128);
    animation: bounce 2s ease-in-out infinite;
  }
}
