.ead__top-section {
  width: 100vw;
  img {
    width: 100%;
  }
  .ead__top-section-upper {
    max-width: 1110px;
    margin: 0 auto;
    span {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 30px;
        height: 1px;
        background: #d9d9d9;
        top: 0;
      }
      h6 {
        font-family: TomatoGrotesk !important;
        padding-top: 8px;
      }
      p {
        opacity: 0.65;
        width: 543px;
      }
      &:first-child {
        justify-content: start;
        margin-bottom: 24px;
      }
      &:nth-child(2) {
        justify-content: flex-end;
        align-items: flex-end;
        h6 {
          text-align: end;
          &::before {
            right: 0;
          }
        }
        p {
          text-align: end;
        }
      }
    }
    .ead__top-section-btn {
      width: 100%;
      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 16px;
        background: linear-gradient(
          94.67deg,
          rgba(255, 255, 255, 0.25) 0%,
          rgba(255, 255, 255, 0.1) 0.01%,
          rgba(255, 255, 255, 0.05) 100%
        );
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(20px);
        border-radius: 16px;
        border: none;
        outline: none;
        margin: 40px auto 0 auto;
        &:hover {
          background: rgb(27, 26, 26);
        }
      }
    }
  }
  .ead__top-section-lower {
    width: 100vw;
    padding-top: 100px;
    background: linear-gradient(0deg, #ff0019b3 -78.51%, rgba(0, 0, 0, 0) 50%);
    background-color: black;
    display: flex;
    flex-direction: column;
    gap: 40px;
    span {
      display: flex;
      justify-content: space-between;
      width: 1110px;
      max-width: 1110px;
      margin: 0 auto;
      position: relative;
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 1110px;
        height: 1px;
        background-color: #d9d9d9;
        top: -10px;
      }
      p {
        font-family: TomatoGrotesk !important;
      }
    }
    .top-section-lower-float {
      width: 1110px;
      max-width: 1110px;
      margin: 0 auto;
      background: #000000;
      border-radius: 24px 24px 0px 0px;
      display: flex;
      gap: 24px;
      justify-content: space-between;
      padding: 30px 24px;
      .ead__top-section-lower-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 14px;
        p {
          font-family: "U8";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          margin: 0;
        }
        h6 {
          text-transform: capitalize;
        }
      }
    }
  }
}
.ead__header {
  display: flex;
  justify-content: space-between;
  width: 1110px;
  max-width: 1110px;
  margin: 0 auto;
  position: relative;
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 1110px;
    height: 1px;
    background-color: #d9d9d9;
    top: -10px;
  }
  p {
    font-family: TomatoGrotesk !important;
    text-transform: capitalize;
  }
}
