.about__impact-info-container {
    padding: 67px 0 67px 24px;
  }
  
  .about__impact-info-header h1 {
    font-family: TomatoGrotesk;
    font-weight: 600;
    font-size: 44px;
    line-height: 53px;
    margin: 0;
  }
  
  .about__impact-info-description {
    font-family: "U8";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0 0 0;
  }