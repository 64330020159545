.blog__top-section {
  width: 100%;
  height: fit-content;
  background: black;
}
.blog__top-section-upper {
  padding: 100px 0 50px 0;
}
.blog__top-section-header header h5 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #ffffff;
  margin: 0;
}
.blog__top-section-header header p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
  margin-top: 18px;
}
.blog__top-section-lower {
  background-image: url(./images/project-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 52px 0 96px 0;
  overflow: hidden;
}
.blog__top-section-img-container {
  height: 401.88px !important;
  overflow: hidden !important;
  margin: 0;
}
.blog__top-section-img-container img {
  width: 100%;
}
