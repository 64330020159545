@mixin font-weights() {
    @each $name, $weight in (
      thin: 100,
      extralight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900
    ) {
      .font-#{$name} {
        font-weight: $weight;
      }
    }
  }
  
  @include font-weights();
  