@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.vaulthill__font-section {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  header {
    width: 100%;
    padding-bottom: 18px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  }
  .vaulthill__font-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    div {
      height: 90px;
      &:first-child {
        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 60px;
          line-height: 90px;
          color: #292929;
          text-transform: uppercase;
          margin: 0;
        }
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 24px;
        h6 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: rgba(41, 41, 41, 0.65);
          width: fit-content;
          margin: 0;
        }
        span {
          display: flex;
          flex-direction: row;
          gap: 24px;
          p {
            font-family: "Poppins";
            font-style: normal;
            font-size: 20px;
            line-height: 30px;
            margin: 0;
          }
        }
      }
    }
  }
  figure {
    margin: 0;
    width: 100%;
    overflow: hidden;
    height: 801.5px;
    max-height: 801.5px;
    img {
      width: 100%;
    }
  }
}
