.services__top-section {
  background: white;
  padding: 100px 0;
  width: 100%;
  height: fit-content;
}
.services__top-section-header {
    width: 847px;
    height: 192px;
  margin: 0 auto;
}
.services__top-section-header header {
    width: fit-content;
  margin: 0;
  padding: 0;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.services__top-section-header header h3 {
  position: relative;
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  text-align: center;
  color: #000000;
  margin: 0;
}
.services__header-text-sketch {
    position: absolute;
    width: fit-content;
    height: fit-content;
}
.services__header-text-sketch {
  left: 45%;
  right: 21.53%;
  top: -5px;
}
.services__header-text-sketch img {
    height: 125px;
    width: 490px;
}
.services__top-section-header header p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 0;
}
