.ead__catalogue {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .catalogue__img-container {
    position: relative;
    width: 100%;
    margin: 0;
    background: linear-gradient(
      94.67deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.1) 0.01%,
      rgba(255, 255, 255, 0.05) 100%
    );
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
    align-items: center;
    img {
      &:first-child {
        width: 72.97%;
      }
      &:nth-child(2) {
        position: absolute;
        right: 45px;
        top: 19.6%;
        width: 250px;
      }
    }
    .img-container__lower {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 1020px;
      gap: 100px;
      img {
        width: 250px;
      }
      span {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: flex-start;
        width: 460px;
        max-width: 460px;
        &::before {
          position: absolute;
          display: block;
          content: "";
          width: 206px;
          height: 1px;
          background: #ffffff;
          top: 0;
        }
        p {
          color: white;
          &:first-child {
            font-family: TomatoGrotesk !important;
            padding-top: 16px;
          }
          &:last-child {
            font-family: "U8";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            opacity: 0.65;
            margin: 0;
          }
        }
      }
    }
  }
}
