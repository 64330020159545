@import url("https://fonts.cdnfonts.com/css/inter");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.jazzy__typography {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .flex-container {
    display: flex;
    gap: 24px;
    div {
      width: 50%;
      height: 490px;
      &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h1 {
          position: relative;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 100px;
          line-height: 150px;
          color: #000000;
          margin: 0;
          &::after {
            position: absolute;
            display: block;
            content: "";
            width: 100px;
            height: 2px;
            background: rgba(0, 0, 0, 0.65);
            border-radius: 100px;
          }
        }
        p {
          font-family: "Inter" !important;
        }
      }
      &:last-child {
        position: relative;
        span {
          position: absolute;
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 197.5px;
          border-radius: 32px;
          &:first-child {
            top: 0;
            background: #000000;
          }
          &:nth-child(2) {
            top: 97.5px;
            background: #f0f0f0;
          }
          &:nth-child(3) {
            top: 195px;
            background: #ff9182;
          }
          &:last-child {
            top: 292.5px;
            background: #ff2709;
          }
          p {
            font-family: "Poppins" !important;
            padding: 24px 24px 0 24px;
          }
        }
      }
    }
  }
}
