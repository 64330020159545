.get-estimate {
  width: 100%;
  min-height: 200px;
  border-radius: 32px 32px 0 0;
  background-color: #000000;
  margin-bottom: 50px !important;
}

.cta-intro {
  color: #fff;
  padding-left: 50px;
  position: relative;
  min-height: 200px;
}

.cta-intro-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.cta-intro h3 {
  font-family: "Tomato Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.cta-intro p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0 0 0;

  color: rgba(255, 255, 255, 0.6);
}

.estimate-form {
  grid-column-end: -1 !important;
}

.estimate-form-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 30px;
  align-content: center;
  justify-content: center;
}
.estimate-form-grid-2 {
  grid-column: span 2;
  position: relative;
  min-height: 200px;
}

.estimate-form-grid-5 {
  grid-column: span 5;
  position: relative;
}

.estimate-form-input {
  height: 40px;
  left: 475px;
  top: 50px;
}

.estimate-form-text,
.estimate-form-input {
  max-width: 90%;
  min-width: 90%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  outline: 1px solid rgba(255, 255, 255, 0.7);
  border: none;
  padding: 0 5% 0 5%;
  font-family: "U8";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  transition: all 0.5s;
  color: #ffffff;
}

.estimate-form-text {
  height: 88px;
  resize: none;
  padding-top: 12px;
  /* width: max-width !important; */
}
.estimate-form-input:hover,
.estimate-form-input:active,
.estimate-form-input:focus,
.estimate-form-text:hover,
.estimate-form-text:active,
.estimate-form-text.focus {
  background-color: #ffffff;
  color: #000000;
}

.estimate-form-spacer {
  height: 20px;
}

.estimate-form-item-holder,
.estimate-form-message-holder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.estimate-form-item-holder {
  width: 100%;
}

.estimate-form-message-holder {
  width: stretch;
  padding-right: 50px;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .cta-intro,
  .estimate-form {
    grid-column: span 12 !important;
  }
  .cta-intro {
    height: auto !important;
    min-height: 69px;
    margin-top: 50px;
  }
  .estimate-form-grid-2,
  .estimate-form-grid-5 {
    min-height: 100px;
  }
  .estimate-form-grid {
    padding: 50px 50px;
  }
  .estimate-form-message-holder {
    padding-right: 0;
  }
  .estimate-form-message-holder textarea {
    padding-left: 10px;
    width: max-available;
  }
}

@media only screen and (max-width: 500px) {
  .get-estimate {
    display: none !important;
  }
}
