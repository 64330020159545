.about__impact-section {
  background-image: url(./images/impact-section_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content;
  padding: 100px 0 153px 0;
}
.about__impact-section-img-container {
  height: 379.98px;
  overflow: hidden;
}
.about__impact-section-img-container img {
  width: 100%;
}
