.project-section {
  background-color: #000000;
  padding: 100px 0;
}

.project-section-header h1 {
  color: #ffffff;
  text-align: center;
  /* font-family: TomatoGrotesk"; */
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}

.project-section-header {
  margin: 0 auto 100px;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .project-section {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .project-section-header {
    margin-bottom: 0;
  }
}
