.valthill__grid-section {
  .valthill__grid-section-inner {
    max-width: 1110px;
    margin: 0 auto;
    div {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(12, 1fr);
      // height: 1320px;
      // max-height: 1320px;
      column-gap: 24px;
      row-gap: 60px;
      span {
        height: fit-content;
      }
      p {
        text-align: center;
      }
      span:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        grid-row: 1 / span 2;
        img {
          width: 100%;
        }
      }
      span:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        grid-row: 1 / span 2;
        margin-top: 144px;
        img {
          width: 100%;
        }
      }
      span:nth-child(3) {
        grid-row-start: 3;
        margin-top: -195px;
        img {
          width: 100%;
        }
      }
      span:nth-child(4) {
        img {
          width: 100%;
        }
      }
    }
  }
  .banner {
    position: relative;
    width: 100vw;
    height: fit-content;
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 100vw;
      height: 5px;
      background-color: black;
      bottom: -0.5px;
    }
    img {
      width: 100%;
    }
  }
}
