:root {
  --top-section-height: calc(
    100vh - (var(--nav-bar-height) + var(--notification-bar-height))
  );
}

.about__top-section {
  height: fit-content;
  background-color: #ffffff;
}

.about__top-lhs,
.about__top-rhs {
  height: var(--top-section-height);
  max-height: 884px;
  min-height: 690px;
  background: #ffffff;
}
.about__top-section-lower{
    width: fit-content;
    grid-column-end: -3 !important;
    margin-bottom: 11.38px;
}
.about__top-section-header {
  width: fit-content;
  margin: 0 auto;
}
.about__top-section-header h3 {
  font-family:TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}
.about__top-section-logos {
  display: flex;
  align-items: center;
  gap: 120px;
  width: fit-content;
  height: fit-content;
  margin: 36px auto 0 auto ;
}
.about__vaulthill-logo {
  width: 70.62px;
  height: 89.12px;
}
.about__yaaaga-logo {
  width: 339.66px;
  height: 52.13px;
}
.about__EAD-logo {
  width: 106px;
  height: 90.62px;
}
