.social-icons-lite {
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 114px;
  height: 16px;
}
.social-icons-lite svg {
    width: 14px;
    height: 16px;
}