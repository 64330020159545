.about__team-section {
    height: fit-content;
    background-color: #000000;
    padding-bottom: 100px;
  }
  .about__team-section-top{
    width: fit-content;
    grid-column: 4/10!important;

}
.about__team-section-header {
  width: fit-content;
  height: 100%;
  margin: 0 auto;
  padding: 100px 0;
}
.about__team-section-header h3 {
  font-family:TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
  color: #FFFFFF;
  text-align: center;
}
.about__team-section-header p {
    font-family: U8;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }
  .about__team-section_LTR {
    background-image: url(./images/team_bg-LTR.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 330px;
  }
  .about__team-section_RTL {
    background-image: url(./images/team_bg-RTL.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 330px;
  }
  .about__team-section-inner_LTR {
    height: fit-content;
    padding: 50px 0;
  }

  .about__team-section-inner_RTL {
    height: fit-content;
    padding: 50px 0;
  }

  .about__team-section-inner_RTL_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 78px;
  }