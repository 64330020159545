.yaaaga__thanks-section {
    display: grid;
  width: 100vw;
  background-image: url(./images/project-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 99.67px 0 170.49px 0;
  .yaaaga__thanks-section-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    max-width: 1110px;
    margin: 0 auto;
    div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      span {
        width: 199px;
        height: 76px;
        p {
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          text-align: center;
          color: #ffffff;
          margin: 0;
        }
      }
    }
    div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      span:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        p:first-child {
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 48px;
          text-align: center;
          color: #ffffff;
          margin: 0;
        }
        p:last-child {
          font-family: TomatoGrotesk !important;
          opacity: 0.65;
        }
      }
      span:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        width: 500px;
        input {
            width: 474px;
           max-width: 500px;
          padding: 12px;
          background-color: #000000;
          border: 1px solid rgba(255, 255, 255, 0.7);
          border-radius: 8px;
          outline: none;
          transition: all 0.5s;
          &::placeholder {
            font-family: "U8";
            font-style: normal;
            font-weight: 350;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.5);
          }
          &:hover {
            background-color: #ffffff;
          }
          &:focus{
            background-color: #ffffff;
          }
        }
        textarea {
            width: 474px;
            max-width: 500px;
          height: 100px;
          padding: 12px;
          background-color: #000000;
          border: 1px solid rgba(255, 255, 255, 0.7);
          border-radius: 8px;
          outline: none;
          resize: none;
          transition: all 0.5s;
          &::placeholder {
            font-family: "U8";
            font-style: normal;
            font-weight: 350;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.5);
          }
          &:hover {
            background-color: #ffffff;
          }
          &:focus{
            background-color: #ffffff;
          }
        }
        button {
          text-align: center;
          padding: 16px 30px;
          width: 500px;
          height: 49px;
          background: #00ba80;
          border-radius: 8px 8px 0px 0px;
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }
    }
  }
  .yaaaga__date {
    display: flex;
    align-self: center;
    justify-self: center;
    font-family: "U8";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin-top: 50px;
    position: relative;
    z-index: 1000;
    &::after {
        position: absolute;
        content: '';
        width: 14px;
        height: 6px;
        background: #00BA80;
        right: 0;
        top: 17%;
        z-index: -1;
    }
  }
}
