.top-rhs {
  grid-column-end: -1 !important;
  width: 100%;
  position: relative;
}
.rhs-inner {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: inherit;
}
.image-slide-container {
  position: relative;
  height: max-content;
  height: 528px;
}

.image-holder {
  width: 350px;
  height: 472px;

  top: 20px;
  left: 30px;
  border-radius: 32px 32px 0px 0px;
  transform: rotate(-6.5deg);
  z-index: 10000;
  overflow: hidden;
}

.triangle,
.scratch,
.hexagon,
.image-holder {
  position: absolute;
}

.triangle {
  top: 124px;
  z-index: 10001;
}

.scratch {
  bottom: 0;
  z-index: 10001;
}

.hexagon {
  bottom: 16px;
  right: 0;
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .hexagon {
    bottom: 27px;
    right: 0;
    text-align: right;
  }
  .hexagon img {
    width: 60%;
  }
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .top-rhs {
    display: none;
  }
}
