.blog-section {
  padding: 100px 0;
  background-color: #ffffff;
}

.blog-intro {
  margin-bottom: 70px !important;
}

.blog-intro h3 {
  font-family: "TomatoGrotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}

.blog-text p {
  font-family: "U8";
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.blog-text {
  grid-column-end: -1 !important;
}

/* Tab Settings */

@media only screen and (max-width: 999px) {
  .blog-header,
  .blog-text,
  .blog-item {
    grid-column: span 12 !important;
  }
  .blog-items {
    grid-row-gap: 50px !important;
  }
  .blog-section {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .blog-header,
  .blog-text,
  .blog-item {
    grid-column: span 2 !important;
  }
}
