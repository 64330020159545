body .cursor {
  pointer-events: none;
}
body .cursor__ball {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 1000;
}
body .cursor__ball circle {
  fill: #f7f8fa;
}

@media screen and (max-width: 769px) {
  body .cursor,
  body.cursor__ball,
  body.cursor__ball circle {
    display: none;
  }
  body {
    cursor: pointer;
  }
}
