.passion-section {
  background-image: url(./images/psasion-section-bg.jpg);
  background-size: cover;
  padding: 100px 0;
}

.passion-image-container {
  height: 379.88px;
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.passion-image-container img {
  width: 100%;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .passion-image-container {
    grid-column: span 12 !important;
  }
  .passion-content-container {
    grid-column: span 12 !important;
  }
  .passion-section {
    padding-bottom: 0px;
  }
}

/* Tab Settings */
@media only screen and (max-width: 500px) {
  .passion-image-container {
    grid-column: span 2 !important;
  }
  .passion-content-container {
    grid-column: span 2 !important;
  }
  .passion-section {
    padding-bottom: 0px;
  }
}
