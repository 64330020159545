.faqs-section {
  width: 100%;
  padding: 100px 0 50px 0;
  background: white;
}
.faqs-section-inner {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.faqs-section-LHS {
  grid-column-start: 2 !important;
  grid-column-end: 6 !important;
}
.faqs-section-LHS h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #000000;
  text-align: left;
  margin: 0;
  padding: 0;
}
.faqs-section-RHS {
  grid-column-end: -2 !important;
}
.faqs-section-RHS p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000000;
  padding: 0;
  margin: 0;
}
.faqs-section-lower {
  width: 910px !important;
  grid-column-start: 2 !important;
  grid-column-end: 12 !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 30px !important;
}
.faqs-section-accordion__open {
  width: fit-content;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.faqs-section-accordion {
  width: fit-content;
  height: fit-content;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.faqs-section-accordion__open p {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  padding: 0;
  margin: 0;
  padding-bottom: 20.37px;
}
.faqs-section-accordion p {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  padding: 0;
  margin: 0;
  padding-bottom: 20.37px;
}
.faqs-section-accordion h6 {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0;
  padding-bottom: 20.37px;
}
.hidden {
  visibility: hidden;
}
.block {
  visibility: visible;
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  margin: 0;
  padding-bottom: 20.37px;
}
