.project-image-container {
  height: 500px;
  border-radius: 32px 32px 0 0;
  overflow: hidden;
}
.project-image img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 500px) {
  .project-image-container {
    width: 100%;
    height: auto !important;
  }
}
