.about__story-section {
    background-image: url(./images/services-section-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;
  }
  .about__story-section-top {
    margin-bottom: 100px;
  }
  .about__story-section-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
    height: fit-content;
    grid-column-end: -4 !important;
    margin: 0;
  }
  .about__story-section-header h3 {
    font-family: TomatoGrotesk;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 53px;
    text-align: center;
    margin: 0;
  }
  .about__story-section-header p {
    font-family: U8;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    width: 540px;
    margin: 0;
  }