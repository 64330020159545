.blur-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.236) 10%,
    rgba(255, 255, 255, 0.236) 50%,
    rgba(255, 255, 255, 0.236) 90%
  );
  border: none !important;
  animation: loaderSwipeAnim 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.blur-loader-container {
  /* background-color: #eee; */
  background: rgba(207, 247, 227, 0.634);
  filter: blur(2px);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
}
