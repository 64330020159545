.ead__Cart-and-tracking {
  max-width: 1110px;
  margin: 0 auto;
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    figure {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 100px 0 197.5px 0;
      background: linear-gradient(
        94.67deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0.1) 0.01%,
        rgba(255, 255, 255, 0.05) 100%
      );
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
      backdrop-filter: blur(20px);
      border-radius: 24px;
      overflow: hidden;
      margin: 0;
      img {
        &:first-child {
          position: relative;
          width: 72.97%;
        }
        &:last-child {
          position: absolute;
          width: 250px;
          top: 295px;
          right: 45px;
        }
      }
    }
  }
}
