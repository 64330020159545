.lynk__landing-page {
  .lynk__landing-page-top {
    width: 100vw;
    background: #042f24;
    .top-container {
      max-width: 1110px;
      margin: 0 auto;
      figure {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        margin-top: 32px;
        height: fit-content;
        img {
          &:first-child {
            width: 81.08%;
          }
          &:nth-child(2) {
            position: absolute;
            top: 364px;
            right: 0;
            width: 260px;
          }
          &:last-child {
            position: absolute;
            bottom: 266.46px;
            left: 0;
            width: 260px;
          }
        }
      }
    }
  }
  .landing-page-lower {
    width: 100vw;
    display: flex;
    margin: 0;
    padding: 16px 0;
    img {
      width: 50%;
    }
  }
}
