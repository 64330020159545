.jazzy__screens {
  position: relative;
  width: 100vw;
  img {
    width: 100%;
  }
  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 100vw;
    height: 5px;
    background-color: black;
    bottom: -0.5px;
  }
}
