.jazzy__logo-section {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .jazzy-flex {
    display: flex;
    figure {
      margin: 0;
      width: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .jazzy-flex2 {
    display: flex;
    gap: 24px;
    figure {
      margin: 0;
      width: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
}
