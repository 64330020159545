.project-content {
  height: 500px;
  color: #ffffff;
  text-align: center;
  position: relative;
}
.project-content-inner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.project-title h1 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}

.project-description p {
  font-family: "U8";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 20px 0 0;
  color: rgba(255, 255, 255, 0.5);
}

.project-see-more {
  margin: 40px auto 0;
  width: 54px;
  height: 54px;
  transition: all 0.5s;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .project-content {
    grid-column: span 12 !important;
    height: max-content !important;
    padding: 20px 0;
  }
  .project-content-inner {
    position: relative;
    top: 0;
    left: unset;
    transform: unset;
    width: max-content;
    margin: 0 auto;
  }
  .project-image {
    grid-column: span 12 !important;
  }
  .project-image-container {
    max-width: 445px;
    height: 500px;
    margin: 0px auto !important;
  }
}
