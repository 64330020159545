.passion-content-container {
  padding: 67px 0 67px 24px;
}

.passion-section-header h1 {
  font-family: TomatoGrotesk;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}

.passion-description {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 0 0;
}

.passion-ratings {
  height: max-content !important;
  margin-top: 20px;
}

.passion-rating-clear {
  clear: both;
}
.passion-rating-item {
  margin-right: 24px;
  width: max-content;
}

.passion-rating-item,
.passion-rating-rating,
.passion-rating-info {
  float: left;
}

.passion-rating-item .passion-rating-rating h1 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}

.passion-rating-info {
  height: 53px;
  position: relative;
}

.passion-rating-info p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-left: 10px;
  margin: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: max-content;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .passion-content-container {
    padding: 50px 0 80px 24px;
  }
}

@media only screen and (max-width: 500px) {
  .passion-rating-item {
    margin-right: 16px;
  }
  .passion-content-container {
    padding-left: 0;
  }
  .passion-rating-item .passion-rating-rating h1 {
    font-size: 34px;
  }
}
