.main-footer {
  padding-top: 50px;
}

.footer-bottom {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  align-items: center;
  margin-top: 40px !important;
}

.footer-links ul {
  display: inline-flex;
  list-style-type: none;
  gap: 40px;
  padding: 0;
}

.footer-links ul li a {
  text-decoration: none;
  color: #000000;
}

.footer-copyright p {
  margin: 0;
  text-align: right;
}
