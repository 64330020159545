.ead__landing-screen {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  figure {
    position: relative;
    width: 100%;
    margin: 0;
    background: linear-gradient(
      94.67deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.1) 0.01%,
      rgba(255, 255, 255, 0.05) 100%
    );
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(20px);
    border-radius: 24px;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    img {
      &:first-child {
        width: 72.97%;
      }
      &:nth-child(2) {
        position: absolute;
        right: 45px;
        top: 27%;
        width: 250px;
      }
      &:last-child {
        position: absolute;
        left: 45px;
        width: 250px;
        bottom: 21.3%;
      }
    }
  }
}
