.pricing__top-section {
  background: white;
  width: 100%;
  height: fit-content;
  padding: 100px 0;
}
.pricing__top-section-header {
    grid-column-end: -3 !important;
}
.pricing__top-section-header header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  color: #000000;
  text-align: center;
  margin: 0;
}
.pricing__top-section-header header p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 0;
  margin-top: 20px;
}
