.rtl-project-item {
  background-image: url(./images/bg=rtl.jpg);
}

.ltr-project-item {
  background-image: url(./images/bg=ltr.jpg);
}

.project-item {
  padding: 50px 0;
  background-size: cover;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .rtl-project-item .uigrid {
    display: flex;
    flex-direction: column-reverse;
    grid-row-gap: 50px;
  }
  .rtl-project-item {
    background-position: right top;
  }
  .project-item .uigrid {
    grid-row-gap: 50px !important;
  }
}

@media only screen and (max-width: 500px) {
  .ltr-project-item,
  .rtl-project-item {
    background: #000000 !important;
  }
}
