.about__top-rhs {
  grid-column-end: -1 !important;
  width: 100%;
  position: relative;
}
.about__rhs-inner {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: inherit;
}
.about__image-slide-container {
  position: relative;
  height: max-content;
  height: 540px;
}

.about__image-holder {
  width: 350px;
  height: 433px;
  overflow: hidden;
  }
  .about__image-holder img{
    width: 100%;
    height: 100%;
  }



/* Tab Settings */
@media only screen and (max-width: 999px) {
  .top-rhs {
    display: none;
  }
}
