.top-lhs {
  position: relative;
}

.lhs-inner {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-text {
  position: relative;
}

.header-text h1 {
  font-family: TomatoGrotesk;
  font-weight: 600;
  font-size: 74px;
  margin: 0px;
  line-height: 89px;
}
.header-description {
  margin-top: 60px;
}

.header-description p {
  font-family: U8;
  font-weight: 400;
  font-size: 19px;
  margin: 0px;
}

.header-description p span {
  font-weight: 600;
}

.header-links {
  margin-top: 40px;
}

.header-link-btn {
  font-size: 14px;
  font-weight: 500;
  padding: 16px 30px;
  display: inline-block;
  width: max-content;
  background: var(--awa-green);
}

.header-links-more {
  font-size: 14px;
  display: inline-block;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  /* font-family: TomatoGrotesk; */
}

.header-see-more {
  color: #000000;
  padding-left: 10px;
}

.header-text-sketch {
  position: absolute;
  left: -17px;
  bottom: -25px;
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .header-text h1 {
    font-family: TomatoGrotesk;
    font-weight: 600;
    font-size: 69px;
    margin: 0px;
    line-height: 89px;
  }
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .top-lhs {
    grid-column: span 12 !important;
    height: 725px !important;
  }
  .top-section {
    height: max-content !important;
    min-height: max-content !important;
  }
}

/* Mobile Settings */
@media only screen and (max-width: 500px) {
  .top-lhs {
    grid-column: span 2 !important;
    min-height: 541px !important;
    height: auto !important;
  }
  .top-section {
    height: max-content !important;
    min-height: max-content !important;
  }
  .header-text h1 {
    font-size: 44px;
    line-height: 53px;
  }
  .header-text-sketch img {
    width: 213px;
  }
  .header-description {
    margin-top: 40px;
  }
  .header-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .header-link-btn {
    display: block;
  }
  .header-links-more {
    margin-left: 0;
  }
}
