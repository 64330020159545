.blog__article-section{
    position: relative;
    background: white;
    width: 100%;
}
.blog__items-container {
  height: fit-content;
  margin-top: 70px;
  padding-bottom: 100px;
}
.blog__items-container-flex {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
}
.blog__article-section-header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
}
