.how-section {
  padding: 50px 0;
  background-color: rgb(255, 219, 219);
  background-image: url(./images/services-section-bg.jpg);
  background-size: cover;
}

.how-header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  margin: 0;
}
.how-text {
  align-self: center;
  justify-self: center;
  grid-column-end: -1 !important;
}

.how-text p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  margin: 0;
}
.service-items {
  margin-top: 80px !important;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .how-header,
  .how-text {
    grid-column: span 12 !important;
  }
  .how-section {
    background-position: right top;
  }
}

@media only screen and (max-width: 500px) {
  .how-text p {
    font-size: 14px;
    line-height: 24px;
  }
  .how-text {
    padding-top: 20px;
  }
}
