.caseStudy__lynk-top-section {
  .hero-container {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .lynk-top-section__lower {
    padding: 100px 0;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      max-width: 1110px;
      margin: 0 auto;
      span {
        display: flex;
        flex-direction: column;
        gap: 9px;
        h6 {
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          color: #292929;
          margin: 0;
          padding: 0;
        }
        p {
          font-family: "U8";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: rgba(41, 41, 41, 0.85);
          margin: 0;
          padding: 0;
        }
      }
      .lynk-top-section__lower-launch-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 26px;
        position: absolute;
        top: 0;
        right: 0;

        // transform: translateX(100%);
        p {
          font-family: "U8";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;
          color: #292929;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
