.footer {
  padding: 50px 0;
  background-color: #ffffff;
}

@media only screen and (max-width: 500px) {
  .footer {
    padding: 0;
  }
}
