.ead__solution-section {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  div {
    &:first-child {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: relative;
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: 1110px;
        height: 1px;
        background-color: #d9d9d9;
        top: -10px;
      }
      p {
        font-family: TomatoGrotesk !important;
      }
    }
    &:last-child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 24px;
      figure {
        margin: 0;
        width: 626px;
        max-height: 300px;
        height: 300px;
        overflow: hidden;
        img {
          width: 100%;
          max-height: 300px;
        }
        &:nth-child(2) {
          position: relative;
          width: 460px;
          background: #babfc3;
          box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
          backdrop-filter: blur(20px);
          border-radius: 24px;
          height: 300px;
          img {
            position: absolute;
            width: 230px;
            height: 250.01px;
            top: 58.5%;
            right: 0;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &:nth-child(3) {
          width: 460px;
        }
        &:last-child {
          position: relative;
          width: 460px;
          background: #babfc3;
          box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
          backdrop-filter: blur(20px);
          border-radius: 24px;
          height: 300px;
          img {
            position: absolute;
            width: 390px;
            height: 255px;
            top: 57.7%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
