.ead__timeline-section {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  figure {
    margin: 0;
    width: 100%;
    height: 483.82px;
    max-height: 483.82px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .timeline-section-dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 348.67px;
      max-width: 348.67px;
      border-radius: 16px;
      padding: 10px 0;
      background: linear-gradient(
        94.67deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0.1) 0.01%,
        rgba(255, 255, 255, 0.05) 100%
      );
      box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
      backdrop-filter: blur(20px);
      font-family: TomatoGrotesk !important;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
