.contact-form {
  background: white;
}
.contact-form-box {
  grid-column-end: -3 !important;
  width: 100%;
  height: fit-content;
  border-radius: 32px 32px 0 0;
  background-color: #000000;
  padding-bottom: 80px;
}
.contact-form-intro {
  color: #fff;
  padding-left: 50px;
  position: relative;
  min-height: 200px;
}

.contact-form-top-inner {
  width: 80%;
  margin: 50px auto 0;
  color: #fff;
}

.contact-form-top-inner h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.contact-form-top-inner p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0 0 0;

  color: rgba(255, 255, 255, 0.6);
}

.form__contact-form {
  width: 80%;
  margin: 0 auto;
  background: #000000;
  margin-top: 30px;
}

.contact-form-input-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px;
  align-content: center;
  justify-content: center;
}
.contact-form-input {
  width: 50%;
  height: 40px;
}

.contact-form-text,
.contact-form-input {
  max-width: 90%;
  min-width: 90%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  outline: 1px solid rgba(255, 255, 255, 0.7);
  border: none;
  padding: 0 5% 0 5%;
  font-family: "U8";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  transition: all 0.5s;
  color: #ffffff;
}

.contact-form-text {
  grid-column: span 2;
  height: 88px;
  resize: none;
  padding-top: 12px;
  /* width: max-width !important; */
}
.contact__btn-container {
  width: fit-content;
  margin: 60px auto 0;
}
.contact-form-input:hover,
.contact-form-input:active,
.contact-form-input:focus,
.contact-form-text:hover,
.contact-form-text:active,
.contact-form-text.focus {
  background-color: #ffffff;
  color: #000000;
}

.contact-form-spacer {
  height: 20px;
}

.contact-form-item-holder,
.contact-form-message-holder {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.contact-form-item-holder {
  width: 100%;
}

.contact-form-message-holder {
  width: stretch;
  padding-right: 50px;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .contact-form-intro,
  .contact-form {
    grid-column: span 12 !important;
  }
  .contact-form-intro {
    height: auto !important;
    min-height: 69px;
    margin-top: 50px;
  }
  .contact-form-grid-2,
  .contact-form-grid-5 {
    min-height: 100px;
  }
  .contact-form-grid {
    padding: 50px 50px;
  }
  .contact-form-message-holder {
    padding-right: 0;
  }
  .contact-form-message-holder textarea {
    padding-left: 10px;
    width: max-available;
  }
}

@media only screen and (max-width: 500px) {
  .get-contact {
    display: none !important;
  }
}
