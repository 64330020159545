.yaaaga__onboarding {
  .yaaaga-header {
    width: 1110px;
    margin: 0 auto 24px auto;
  }
  .yaaaga__onboarding-item-container {
    display: flex;
    flex-direction: column;
    gap: 150px;
    max-width: 1110px;
    margin: 0 auto;
    .yaaaga__onboarding-item {
        display: flex;
        align-items: center;
        gap: 40px;
      figure {
        margin: 0;
        width: 580px;
        overflow: hidden;
      img {
        width: 100%;
      }
      }
      span {
        width: 490px;
      }
      }
  }
 

}