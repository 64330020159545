.pricing-card header {
  width: fit-content;
  margin: 0 auto;
  padding: 40px 0 20px 0;
}
.pricing-card header h6 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
  margin: 0;
}
.pricing-card header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #000000;
  margin: 0;
}
.pricing-card header h3 span {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.pricing__card-items {
    width: fit-content;
    margin: 0 auto;
}
.pricing__card-items ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 29px;
}
.pricing__card-items ul li {
    list-style: none;
}
.pricing__card-items ul li {
     text-align: center;
}
.pricing-card-btn-container {
    width: fit-content;
    margin: 0 auto;
}
.pricing-card-lower{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px 0 40px 0;
    border-top:  1px solid rgba(0, 0, 0, 0.2);
}
.black-header{
    color: #000000;
}
.white-header{
    color: white;
}
.white-bg {
    background: white;
}
.green-bg {
    background:  #00BA80;
    ;
}
.grey-item {
    color: rgba(0, 0, 0, 0.5);
}
.white-item {
    color: rgba(255, 255, 255, 0.5);
}
.grey-span {
    color: rgba(0, 0, 0, 0.5);
}
.white-span {
    color: rgba(255, 255, 255, 0.5);
}