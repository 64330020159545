.about__client-section {
  height: fit-content;
  background-color: white;
  padding-bottom: 100px;
  padding-top: 47px;
}
.about__client-section-header {
    height: fit-content;
}
.about__client-section-header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #000000;
  margin: 0!important;
}
.about__client-section-main {
    margin-top: 70px;
}