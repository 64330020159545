.about__team-card {
  width: 160px;
  height: fit-content;
}
.about__team-card-img {
  width: 100%;
  height: fit-content;
  margin: 0;
}
.about__team-card-img img {
  width: 100%;
  height: 160px;
}
.about__team-card-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width:fit-content;
  margin: 20px auto 0 auto;
}
.about__team-card-subtitle h5 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.about__team-card-subtitle p {
  font-family: U8;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
