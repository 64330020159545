.lynk__onboarding {
  position: relative;
  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 100vw;
    height: 2px;
    background-color: black;
    bottom: -1px;
  }
  .onboarding-top {
    width: 100vw;
    background: #042f24;
    margin-bottom: 16px;
    .top-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-width: 1110px;
      margin: 0 auto;
      figure {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        margin: 0;
        img {
          &:first-child {
            width: 81.08%;
          }
          &:nth-child(2) {
            position: absolute;
            top: 22.68px;
            right: 0;
            width: 200px;
          }
        }
      }
    }
  }
  .onboarding-lower {
    width: 100vw;
    background: #042f24;
    .lower-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      max-width: 1110px;
      margin: 0 auto;
      figure {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        margin: 0;
        img {
          &:first-child {
            width: 81.08%;
          }
          &:nth-child(2) {
            position: absolute;
            top: 95.84px;
            left: 0;
            width: 260px;
          }
        }
      }
    }
  }
}
