.Vaulthill__company-section {
    position: relative;
.vaulthill__company-background {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 100px;
  .company-background__flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h6 {
      font-family: TomatoGrotesk;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #292929;
      margin: 0;
    }
  }
  div:first-child {
    margin-bottom: 40px;
  }
  .company-section-launch-btn {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 26px;
    margin-top: 49px;
    p {
        font-family: 'U8' !important;
    }
  }
  .company-section__grid-box-container {
    display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 50px;
      column-gap: 40px;
     figure {
      overflow: hidden;
      margin: 0;
      &:first-child {
        grid-column: 1 / span 2;
      }
      &:nth-child(2) {
        grid-column: 1 / span 1;
     }
     &:nth-child(3) {
        grid-column: 2 / span 1;
     }
    img{
        width: 100%;
     }
    
    }
}
  }
  
}
.company-section__bitmap {
    position: absolute;
    bottom: -100px;
    right: 0;
    height: 210.48px;
    object-fit: contain;
  }

