.service-item {
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 2px solid #ffffff;
  transition: all 0.5s;
}

.service-item:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.indicator-image {
  width: 24px;
  height: 24px;
  transition: all 0.5s;
}

.service-title h5 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin: 30px 0 10px;
  line-height: 34px;
}

.service-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.service-link a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .service-item {
    grid-column: span 6 !important;
  }
  .service-items {
    grid-row-gap: 30px !important;
  }
}
