.blog-item-image {
  width: 100%;
  height: 330px;
  border-radius: 32px 32px 0 0;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.blog-item-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.blog-item-date {
  margin: 40px 0 20px 0;
}

.blog-item-date p {
  font-family: "U8";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}

.blog-item-title h3 {
  font-family: TomatoGrotesk;
  font-weight: 800;

  font-size: 24px;
  line-height: 29px;
  width: 82.41%;
}
.blog-item-title h3:hover {
  color: rgb(0, 186, 128);
}
@media only screen and (max-width: 500px) {
  .blog-item-title h3 {
    width: 100%;
  }
}
