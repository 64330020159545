.lynk__logo-section {
  max-width: 1110px;
  margin: 0 auto;
  .banner-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    figure {
      margin: 0;
      height: fit-content;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .logo-section__lower {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .font-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      .font-box {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 50px;
          height: 2px;
          background: #000000;
          bottom: -25px;
        }
        span {
          &:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            p {
              font-family: TomatoGrotesk;
            }
            h5 {
              font-family: TomatoGrotesk;
              font-style: normal;
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
              color: #000000;
              margin: 0;
            }
          }
          &:nth-child(2) {
            p {
              font-family: TomatoGrotesk !important;
              text-align: right;
            }
          }
        }
      }
      .font-box2 {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &::before {
          position: absolute;
          display: block;
          content: "";
          width: 50px;
          height: 2px;
          background: #000000;
          top: -25px;
          right: 0;
        }
        span {
          &:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            h5 {
              font-family: "U8";
              font-style: normal;
              font-weight: 500;
              font-size: 32px;
              line-height: 48px;
              color: #000000;
              margin: 0;
            }
          }
        }
      }
    }
    .ellipse-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      column-gap: 196px;
      row-gap: 24px;
      flex-wrap: wrap;
      .font-item {
        position: relative;
        width: 239px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ellipse-border {
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 150px;
          border-width: 1px;
          border-style: solid;
          border-radius: 60%;
          .ellipse {
            width: 125px;
            height: 125px;
            border-radius: 60%;
          }
        }
        .color-float {
          position: absolute;
          top: 0;
          left: 0;
          width: 35px;
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #000000;
        }
        .hex-float {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 54px;
          p {
            font-family: "U8";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: right;
            color: #000000;
            margin: 0;
          }
        }
      }
    }
  }
}
