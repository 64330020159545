:root {
  --notification-bar-height: 40px;
}

.notification-bar {
  width: 100%;
  height: var(--notification-bar-height);
  background: #000000;
  display: block;
}

.notification-bar-content {
  height: 40px;
  display: relative;
  text-align: center;
}

.notification-bar-content p {
  line-height: 40px;
  margin: 0;
  color: #ffffff;
  font-size: 12px;
}

.nav-close-x {
  position: relative;
  top: -40px;
  float: right;
  width: max-content;
  padding: 8px 0px;
}

.nav-close-x img {
  line-height: 40px;
}

.notification-content-link a {
  color: var(--awa-green);
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
}

/* @media only screen and (max-width: 999px) {
  .notification-bar {
    height: max-content !important;
  }
  .notification-bar-content {
    height: max-content !important;
  }
  .notification-bar-content p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    margin-top: 14px;
  }
} */

@media only screen and (max-width: 500px) {
  .notification-bar {
    height: max-content !important;
    padding: 14px 0;
  }
  .notification-bar-content {
    height: 36px !important;
  }
  .notification-bar-content p {
    margin-top: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
  }
}
