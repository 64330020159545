@import "./Fonts/Fonts";
@include font-weights();

@mixin font-color() {
  @each $name,
    $color
      in (
        void: black,
        dark: #292929,
        extradark: #120d23,
        lite: rgba(41, 41, 41, 0.85),
        light: white
      )
  {
    .#{$name}-text {
      color: $color;
    }
  }
}

@include font-color();

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-small {
  font-family: "U8";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.text-medium-semi {
  font-family: "U8";
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  margin: 0;
}
.text-medium {
  font-family: "U8";
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
}
.text-medium-sm {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

.text-medium-lg {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-size: 30px;
  line-height: 45px;
  margin: 0;
}

.text-large {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-size: 40px;
  line-height: 60px;
  margin: 0;
}
.opacity {
  opacity: 0.65;
}
@keyframes bounce {
  0% {
    left: 0;
    animation-timing-function: ease-out;
  }
  20% {
    left: 20%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(-50px);
  }
  30% {
    left: 30%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(0px);
  }
  40% {
    left: 40%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(-50px);
  }
  50% {
    left: 50%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(0px);
  }
  60% {
    left: 60%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(-40px);
  }
  70% {
    left: 70%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(0px);
  }
  80% {
    left: 80%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(-30px);
  }
  90% {
    left: 90%;
    animation-timing-function: ease-in-out;
    transform: translateY(-50%) translateY(0px);
  }
  100% {
    left: 100%;
    animation-timing-function: ease-out;
  }
}

@keyframes loaderSwipeAnim {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@media only screen and (max-width: 700px) {
  * {
    cursor: default;
  }
}
