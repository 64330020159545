.vaulthill__top-section {
    position: relative;
    width: 100vw;
 img {
  &:first-child {
    width: 100%;
  }
  &:nth-child(2) {
    position: absolute;
    top: -25px;
    left: 0;
    width: 189.29px;
  }
  &:last-child {
    position: absolute;
    bottom: -200px;
    right: 0;
    width: 310.71px;
  }
 }
}