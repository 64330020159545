.UI__profile-text-container {
  justify-self: center;
  align-self: center;
}
.UI__profile-text-header h5 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.UI__profile-text-description p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 19.5px 0 0 0;
}
.UI__profile-text-info {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 40px 0 0 0;
}
.UI__profile-text-info h5 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.UI__profile-text-info p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
}
