.contact__top-section {
  height: fit-content;
  background-color: #ffffff;
  padding-top: 100px;
}
.contact__top-section-top {
  margin-bottom: 28.89px;
}
.contact__top-section-header {
  grid-column-end: -3 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact__top-section-header header {
  width: 630px;
}
.contact__top-section-header header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 96px;
  color: #000000;
  text-align: center;
  margin: 0;
}
.contact__top-section-header header p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 20px 0 0 0;
}

.contact__top-section-logos {
  grid-column-end: -5 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 55.99px;
}
.contact__top-section-logos a img {
  width: 100%;
}
