.yaaaga__timeline-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 1110px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    h5 {
        padding-left: 19px;
    }
}