.screenshots {
    width: 50%;
}
.customized-menu-container {
    display: flex;
    gap: 60px;
}

.customized-menu-container .screenshots {
    width: 40%;
}
.jazzy-links a {
    color: #292929;
}

/* Tablet */

@media screen and (max-width: 866px) {
    .screenshots {
        width: 50%;
    }
}

/* Mobile */
@media screen and (max-width: 563px) {
    .blogPage__article-section-main p {
        font-size: 18px;
        line-height: 27px;
      }
      .jazzy-links a {
        overflow-wrap: break-word !important;
      }
      .screenshots {
        width: 80%;
    }
    .customized-menu-container {
        gap: 5px;
    }
    .customized-menu-container .screenshots {
        width: 50%;
    }
}