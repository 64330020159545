:root {
  --nav-bar-height: 100px;
}

.navbar-main {
  height: var(--nav-bar-height);
  background: #ffffff;
}
.navbar-content {
  height: 100%;
}

.navbar-logo {
  height: 100%;
  width: auto;
  text-align: center;
  line-height: 44px;
  position: relative;
}

.logo {
  margin: 0;
  position: absolute;
  left: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navbar-links {
  text-align: center;
  height: max-content;
}

.navbar-links ul {
  display: inline-flex;
  list-style-type: none;
  gap: 40px;
  padding: 0;
}

.navbar-links ul li a {
  font-family: U8;
  font-weight: normal;
  color: #000000;
  text-decoration: none;
}

.navbar-button-container {
  position: relative;
  text-align: center;
}
.navbar-btn-identical {
  font-size: 16px;
  padding: 16px 30px;
  background: black;
  width: 220px;
  font-weight: 500;
  text-align: center;
}

.navbar-btn {
  font-size: 12px;
  padding: 13px 18px;
  position: absolute;
  left: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: stretch;
  font-weight: 500;
}

a.nav-active {
  color: rgb(0, 186, 128) !important;
}
.navbar-mobile {
  display: none;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .navbar-button-container {
    display: none;
  }
  .navbar-logo {
    grid-column: span 3 !important;
  }
  .navbar-links {
    text-align: right;
    grid-column: span 9 !important;
  }
}

@media only screen and (max-width: 999px) {
  .navbar-links,
  .navbar-button-container {
    display: none !important;
  }
  .navbar-mobile {
    display: block;
  }
  .navbar-mobile {
    grid-column: span 1 !important;
    grid-column-start: 12 !important;
    text-align: right;
  }
}

@media only screen and (max-width: 500px) {
  .navbar-main {
    height: 82px;
  }
  .navbar-logo {
    grid-column: span 1 !important;
  }
  .logo {
    height: 34px;
  }
  .navbar-mobile {
    display: block;
  }
  .navbar-mobile {
    grid-column: span 1 !important;
    text-align: right;
  }
}
