.blogPage__article-section {
  background-color: white;
  width: 100%;
}

.blogPage__article-section-inner {
  max-width: 1110px;
  padding: 0 10px;
  margin: 0 auto;
  height: fit-content;
}
.blogPage__article-section-main {
  padding-top: 18px;
}
.blogPage__article-section-main p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #292929;
  margin: 32px 0;
}
.blogPage__article-section-main p:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 32px;
}
.vaulthill-links a {
  color: #292929;
}

/*  Tab   */
@media screen and (max-width: 1306px) {
  .blogPage__article-section-inner {
    width: 80%;
    padding: 0;
  }
}

/* Mobile */
@media screen and (max-width: 563px) {
    .blogPage__article-section-main p {
        font-size: 18px;
        line-height: 27px;
      }
      .vaulthill-links a {
        overflow-wrap: break-word !important;
      }
}
