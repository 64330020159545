.vaulthill__timeline-section {
    position: relative;
.vaulthill__timeline-section-img {
    max-width: 1110px;
    margin: 0 auto;
    height: 478.5px;
    overflow: hidden;
    img {
        width: 100%;
    }
}
.cube-img {
    width: 117.18px;
    height: 132.77px;
    object-fit: cover;
    position: absolute;
    left: 0;
    bottom: -75px;
}
}