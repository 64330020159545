:root {
  --top-section-height: calc(
    100vh - (var(--nav-bar-height) + var(--notification-bar-height))
  );
}

.top-section {
  height: var(--top-section-height);
  max-height: 884px;
  min-height: 690px;
  background-color: #ffffff;
}

.top-lhs,
.top-rhs {
  height: var(--top-section-height);
  max-height: 884px;
  min-height: 690px;
  background: #ffffff;
}
