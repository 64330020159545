.vaulthill__journey-section {
  width: 100vw;
  background: linear-gradient(
    225deg,
    rgba(50, 197, 255, 0.1) 0%,
    rgba(182, 32, 224, 0.1) 51.26%,
    rgba(247, 181, 0, 0.1) 100%
  );
  border-radius: 24px;
  .vaulthill__journey-section-inner {
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
    header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 180px;
      span {
        width: 539px;
        h5 {
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          margin: 0;
        }
        &:last-child {
          display: flex;
          flex-direction: column;
          gap: 16px;
          p:first-child {
            position: relative;
            font-family: TomatoGrotesk !important;
            padding-top: 16px;
          }
          &::before {
            position: absolute;
            content: "";
            display: block;
            width: 145px;
            height: 1px;
            background: #120d23;
          }
        }
      }
    }
    div {
      width: 100%;
      position: relative !important;
      display: flex;
      justify-content: center;
      img {
        &:first-child {
          width: 1008px;
          max-width: 1008px;
        }
        &:nth-child(2) {
          position: absolute;
          top: 27.5%;
          right: 0;
          width: 270px;
        }
        &:last-child {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 270px;
          bottom: 14.7%;
        }
      }
    }
  }
}
