.blogPage__top-section {
  width: 100vw;
  height: fit-content;
  background-color: #000000;
}
.blogPage__top-section-inner {
  max-width: 1110px;
  padding: 0 10px;
  margin: 0 auto;
}
.blogPage__top-section-top {
  padding: 100px 0 50px 0;
}
.blogPage__article-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.blogPage__article-header-img {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

.blogPage__article-header-img img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.blogPage__article-header-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.article-header-name-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.article-header-name-container span {
    display: flex;
    gap: 8px;
}
.article-header-name-container h6 {
  font-family: "TomatoGrotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
}

.article-header-name-container p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.65;
  margin: 0;
}

.blogPage__hero-container {
  background-image: url(../images/project-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 553px;
}

.blogPage__hero-inner {
  max-width: 1110px;
  padding: 0 10px;
  margin: 0 auto;
  height: 452.87px;
  overflow: hidden;
}
.blogPage__hero-inner img {
  width: 100%;
  padding-top: 50px;
}

.blogPage-article-title-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 28.5px;
}
.blogPage-article-title-container h3 {
  font-family: "TomatoGrotesk";
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #ffffff;
  margin: 0;
}

.blogPage-article-title-container p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.65;
  margin: 0;
}

/*  Tab   */
@media screen and (max-width: 1306px) {
  .blogPage__top-section-inner {
    width: 80%;
    padding: 0;
  }
  .blogPage__hero-inner {
    width: 80%;
    padding: 0;
  }
}
@media screen and (max-width: 866px) {
  .blogPage__hero-inner {
    height: fit-content;
  }
  .blogPage__hero-container {
    height: fit-content;
  }
  .blogPage__top-section {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 580px) {
    .blogPage__article-header-img {
       min-width: 70px;
    }
} 

/* Mobile */
@media screen and (max-width: 669px) {
    .article-header-name-container span {
        flex-direction: column;
        gap: 8px;
    }
    .blogPage__article-header {
        justify-content: flex-start;
        align-items: flex-start;
      }
}
 
@media screen and (max-width: 581px) {
  .blogPage__article-header-inner {
    display: block;
    /* width: 245px; */
  }
  
  .blogPage__article-header-img {
    width: 60px;
    height: 60px;
    max-width: 60px;
    min-width: 60px;
  }
  .social-icons-lite {
    transform: translateX(-65%);
    margin-top: 16px;
  }
  .social-icons-lite svg {
    width: 20px !important;
  }
}
@media screen and (max-width: 414px) {
  .blogPage-article-title-container h3 {
    font-size: 32px;
    line-height: 48px;
  }
  .article-header-name-container h6 {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 386px) {
    .blogPage__article-header-inner {
        width: 210px;
      }

}
