.pricing__section-container {
  background-image: url(./images/project-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content;
  padding: 100px 0;
}
.pricing-section-top {
  padding-bottom: 100px;
}
.pricing-section-top header {
  grid-column-end: -4 !important;
}
.pricing-section-top header h5 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
