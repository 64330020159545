.footer-sm-item {
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  margin-left: 20px;
}
.footer-sm-item:first-child {
  margin-left: 0;
}
.footer-sm-item svg {
  width: 18px;
  height: auto;
}

.footer-top {
  height: 30px;
}

.footer-sm-links {
  grid-column-end: -1 !important;
  position: relative;
}

.sm-links-inner {
  margin-left: auto;
  width: max-content;
  margin-right: 0;
  height: 18px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.fa-instagram {
  height: 18px !important;
}

.footer-logo {
  height: 30px;
  padding: 0 !important;
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .footer-links {
    grid-column: span 7 !important;
  }
  .footer-links ul {
    gap: 30px;
  }
  .footer-copyright {
    grid-column: span 5 !important;
  }
}

@media only screen and (max-width: 500px) {
  .main-footer {
    padding-top: 0;
  }
  .footer-logo,
  .footer-sm-links,
  .footer-links,
  .footer-copyright {
    grid-column: span 1 !important;
  }
  .footer-links,
  .footer-copyright {
    grid-column: span 2 !important;
  }
  .footer-links ul {
    margin: 0 auto !important;
    align-content: stretch;
    justify-content: center;
    width: 100%;
  }
  .footer-copyright p {
    text-align: center;
  }
  .footer-copyright {
    padding: 20px 0;
  }
}
