.lynk__header {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1110px;
  margin: 0 auto;
  .lynk__header-top {
    display: flex;
    justify-content: space-between;
    width: 1110px;
    max-width: 1110px;
    position: relative;
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 1110px;
      height: 1px;
      background-color: #d9d9d9;
      top: -10px;
    }
    p {
      font-family: TomatoGrotesk !important;
      text-transform: capitalize;
    }
  }
  .lynk__header-lower {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      width: 48.5%;
      text-align: left;
      &:first-child {
        font-family: TomatoGrotesk;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #ffffff;
      }
    }
  }
}
