.ead__screens {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 40px;
  h5 {
    text-align: center;
  }
  img {
    width: 100vw;
  }
}
