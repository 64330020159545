.jazzy__design-section {
  max-width: 1110px;
  margin: 0 auto;
  header {
    width: 547px;
    height: fit-content;
    margin: 0 auto;
    margin-bottom: 32px;
    img {
      width: 100%;
    }
  }
  .jazzy__design-item-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    .jazzy__design-item {
      padding: 0 70px;
      .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .figure1 {
          margin: 0;
          max-width: 270px;
          overflow: hidden;
          height: 727.5px;
          max-height: 727.5px;
          img {
            width: 100%;
          }
        }
        .figure2 {
          margin: 0;
          width: 66.67%;
          img {
            width: 100%;
            background: #ffffff;
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05),
              -10px 0 24px rgba(0, 0, 0, 0.05), 10px 0 24px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
          }
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 600px;
          gap: 50px;
          span {
            display: flex;
            flex-direction: column;
            gap: 32px;
            h3 {
              position: relative;
              &::after {
                position: absolute;
                display: block;
                content: "";
                width: 150px;
                height: 4px;
                background: linear-gradient(135deg, #ff9182 0%, #ff2709 100%);
                border-radius: 100px;
                bottom: -16px;
              }
            }
          }
        }
      }
    }
  }
}
