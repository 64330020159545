.yaaaga__core-section-inner {
  display: flex;
  align-items: center;
  max-width: 1110px;
  margin: 0 auto;
  div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &:first-child {
        width: 58.38%;
      h6 {
        font-family: TomatoGrotesk;
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: #292929;
        margin: 0;
      }
      p {
        font-family: "U8";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: rgba(41, 41, 41, 0.85);
        margin: 0;
      }
    }
    &:last-child {
        width: 41.62%;
      span {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        p {
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #292929;
          margin: 0;
        }
        h5 {
          font-family: TomatoGrotesk;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 45px;
          margin: 0;
          overflow-wrap: break-word !important;
        }
      }
    }
  }
}
