.about__story-content {
   grid-column-end: -2 !important;
}
.about__story-content-inner {
    width: fit-content;
}

.about__story-content-img {
  width: fit-content;
}
.about__story-content-img img {
  width: 100%;
}
