.yaaaga__color-section {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  div {
    display: flex;
    align-items: center;
    gap: 40px;
    width: 100%;
    figure {
      width: 50%;
      margin: 0;
      height: 380.45px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
