@import url("https://fonts.cdnfonts.com/css/ts-block");
.ead__typography-section {
  width: 100vw;
  .ead__typography-section-inner {
    max-width: 1110px;
    margin: 0 auto;
    .typography-section_top {
      display: flex;
      flex-direction: column;
      gap: 40px;
      figure {
        margin: 0;
        width: 100%;
        overflow: hidden;
        height: 570.76px;
        max-height: 570.76px;
        img {
          width: 100%;
        }
      }
      div {
        display: flex;
        flex-direction: row;
        width: 100%;
        span {
          height: 225px;
          width: 100%;
          font-family: "TS Block Bold";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          position: relative;
          &::after {
            position: absolute;
            display: block;
            bottom: 20px;
            left: 20px;
          }
          &:first-child {
            background: #ff0018;
            &::after {
              content: "FF0018";
            }
          }
          &:nth-child(2) {
            background: #171717;
            &::after {
              content: "171717";
            }
          }
          &:last-child {
            background: #ffffff;
            color: #171717 !important;
            &::after {
              content: "FFFFFF";
            }
          }
        }
      }
    }
    .typography-section-lower {
      display: flex;
      flex-direction: column;
      gap: 40px;
      div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        p {
          text-align: center;
          &:first-child {
            font-family: TomatoGrotesk !important;
          }
          &:last-child {
            opacity: 0.65;
          }
        }
      }
      figure {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        img {
          &:first-child {
            width: 561px;
          }
          &:last-child {
            width: 534px;
          }
        }
      }
    }
  }
}
.ead__title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    text-align: center;
    &:first-child {
      font-family: TomatoGrotesk !important;
    }
    &:last-child {
      opacity: 0.65;
    }
  }
}
