.vaulthill__user-section {
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  background: rgba(50, 197, 255, 0.85);
  padding: 50px 0;
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 180px;
    width: 81.98%;
    margin: 0 auto;
    span {
      width: 439px;
      h5 {
        font-family: TomatoGrotesk;
        font-style: normal;
        font-size: 32px;
        line-height: 48px;
        margin: 0;
      }
      &:last-child {
        display: flex;
        flex-direction: column;
        gap: 16px;
        p:first-child {
          position: relative;
          font-family: TomatoGrotesk !important;
          padding-top: 16px;
        }
        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 145px;
          height: 1px;
          background: #ffffff;
        }
      }
    }
  }
  div {
    width: 81.98%;
    margin: 0 auto;
    overflow: hidden;
    height: 651.38px;
    max-height: 651.38px;
    img {
      width: 100%;
    }
  }
}
