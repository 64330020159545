.los-section-container {
  background: white;
  height: fit-content;
  width: 100%;
}
.los-section-container-inner {
  padding: 100px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 196px;
}
.los-section-box {
  height: fit-content;
}
.los-section-box header {
  justify-self: center;
  align-self: center;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.los-section-box header h3 {
  font-family: TomatoGrotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #000000;
  margin: 0;
}
.los-section-box header p {
  font-family: "U8";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.los-section-box-img-container {
  grid-column-end: -1 !important;
  position: relative;
  overflow: hidden;
}
.los-section-box-img {
  width: 100%;
}
.los-section-middle-img {
  position: relative;
  overflow: hidden;
}
.span-vector {
  width: 70.98px;
  height: 59.24px;
  position: absolute;
  bottom: 10px;
  left: -30px;
}
.middle-vector {
  width: 70.98px;
  height: 59.24px;
  position: absolute;
  bottom: 10px;
  right: -30px;
}
.los-section-middle-text {
  grid-column-end: -1 !important;
}
