.about__top-lhs {
    position: relative;
  }
  
  .about__lhs-inner {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-65%);
    transform: translateY(-65%);
  }
  
  .about__header-text {
    position: relative;
  }
  
  .about__header-text h1 {
    font-family: TomatoGrotesk;
    font-weight: 600;
    font-size: 74px;
    margin: 0px;
    line-height: 89px;
  }
  .about__header-description {
    margin-top: 41px;
    width: 445px;
  }
  
  .about__header-description p {
    font-family: U8;
    font-weight: 400;
    font-size: 18px;
    margin: 0px;
  }
  
  .about__header-description p span {
    font-weight: 600;
  }
  
  .about__header-text-sketch {
    position: absolute;
    left: 157px;
    bottom: -25px;
  }
  .TopLHS-ratings {
    display: flex;
    justify-content: space-between;
    height: max-content !important;
    margin-top: 40px;
  }
  
  .TopLHS-rating-clear {
    clear: both;
  }
  .TopLHS-rating-item {
    width: max-content;
  }
  
  .TopLHS-rating-item,
  .TopLHS-rating-rating,
  .TopLHS-rating-info {
    float: left;
  }
  
  .TopLHS-rating-item .TopLHS-rating-rating h1 {
    font-family: TomatoGrotesk;
    font-style: normal;
    font-weight: 600;
    font-size: 44px;
    line-height: 53px;
    margin: 0;
  }
  
  .TopLHS-rating-info {
    height: 53px;
    position: relative;
  }
  
  .TopLHS-rating-info p {
    font-family: "U8";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: max-content;
  }
  
  
  @media only screen and (min-width: 1000px) and (max-width: 1099px) {
    .header-text h1 {
      font-family: TomatoGrotesk;
      font-weight: 600;
      font-size: 69px;
      margin: 0px;
      line-height: 89px;
    }
  }
  
  /* Tab Settings */
  @media only screen and (max-width: 999px) {
    .top-lhs {
      grid-column: span 12 !important;
      height: 725px !important;
    }
    .top-section {
      height: max-content !important;
      min-height: max-content !important;
    }
  }
  
  /* Mobile Settings */
  @media only screen and (max-width: 500px) {
    .top-lhs {
      grid-column: span 2 !important;
      min-height: 541px !important;
      height: auto !important;
    }
    .top-section {
      height: max-content !important;
      min-height: max-content !important;
    }
    .header-text h1 {
      font-size: 44px;
      line-height: 53px;
    }
    .header-text-sketch img {
      width: 213px;
    }
    .header-description {
      margin-top: 40px;
    }
    .header-description p {
      font-size: 16px;
      line-height: 24px;
    }
    .header-link-btn {
      display: block;
    }
    .header-links-more {
      margin-left: 0;
    }
  }
  